'use strict';

import App from 'App';
import Server from 'server/Server';
import Backbone from 'backbone';
import _ from 'lodash';
import RecordModel from 'models/Record';
import Context from 'models/context/Context';
import GetDossierRequest from 'server/protocol/request/task/GetDossier';
import PrepareNewPageRequest from 'server/protocol/request/task/PrepareNewPage';
import SubmitPageRequest from 'server/protocol/request/task/SubmitPage';
import DeviceUtils from 'utils/DeviceUtils';
import ToastFactory from 'utils/ToastFactory';
import LoadingMask from 'views/loading/LoadingMask';
import RecordView from 'views/record/Record';
import PageView from 'views/tasks/Page';
import { SimpleTask } from 'views/tasks/Simple';
import TabBarView from 'views/tasks/TabBar';
import PageNavigatorView from 'views/tasks/navigators/pages/PageNavigator';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import MenuItemView from './MenuItem';
import { PopupMenu } from '../../parametrage/structures/PopupMenu';
import { TaskPresentation, TaskWindowType } from './Task';
import { AbstractTask } from '../../parametrage/structures/AbstractTask';
import DialogUtils from '../../utils/DialogUtils';
import PopupMenuNavigator from './navigators/simple/PopupMenuNavigator';
import { PageInstance } from '../../parametrage/structures/PageInstance';

class DossierTask extends SimpleTask {
    initialize(options){
        super.initialize(options);
        this.newBannerTpl = require('ejs-loader!templates/DossierNewBanner.ejs');
        this.dossierOperations = [];
        {
            let closeIconPath = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/sai-close');
            let closeMenuItem = new MenuItemView({
                label: 'Fermer le dossier',
                iconPath: closeIconPath,
                actionType: 'close',
                id : 'CLOSE'
            });
            this.dossierOperations.push(closeMenuItem);
        }
        {
            let newPageIconPath = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/sai-pagenew');
            let newPageItem = new MenuItemView({
                label: 'Nouvelle page',
                iconPath: newPageIconPath,
                actionType: 'newpage',
                id : 'NEWPAGE'
            });
            this.dossierOperations.push(newPageItem);
        }
        {
            let savePageIconPath = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/sai-pagesave');
            let savePageItem = new MenuItemView({
                label: 'Sauver page',
                iconPath: savePageIconPath,
                actionType: 'savepage',
                id : 'SAVEPAGE'
            });
            this.dossierOperations.push(savePageItem);
        }
        {
            let delPageIconPath = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/sai-pagetrash');
            let delPageItem = new MenuItemView({
                label: 'Supprimer page',
                iconPath: delPageIconPath,
                actionType: 'deletepage',
                id : 'DELETEPAGE'
            });
            this.dossierOperations.push(delPageItem);
        }
        this.navigatorType = 'fullscreen-header';
        this.isFullScreenRecord = true;
    }

    initInnerTaskProperties(){
        this.taskType = 'Dossier';
        this.className = 'dossierTask';
    }

    initPresentation() {
        return super.initPresentation() | TaskPresentation.ShowPageTree | TaskPresentation.ShowPageHeader;
    }

    getRemovedPresentationFromType(type) {
        let flags = super.getRemovedPresentationFromType(type);
        switch(type) {
            case TaskWindowType.FullSimplified:
                return flags | TaskPresentation.ShowPageTree
            case TaskWindowType.MicroPopup:
                return flags | TaskPresentation.ShowPageTree | TaskPresentation.ShowPageHeader;
        }
        return 0;
    }

    render () {
        super.render();

        this.dossierEntry = this.$el.find('.dossierRow');
        this.dossierEntry.hide();
        //Setting inner nodes refs
        this.pageNavEl = this.dossierEntry.children('.pagesNavigatorContainer');
        this.recordEl = this.dossierEntry.children('.dossierRecordContainer');

        this.dossierHeaderEl = this.$el.find('.dossierHeader');
        this.recordBannerEl = this.$el.children('.recordBanner');

        var displayMode = DeviceUtils.isFullScreenPageNav() ? 'tablet' : 'desktop';
        this.setTaskLayout(displayMode);
    }

    switchToAlbum (){
        if(this.recordBanner){
            this.recordBanner.$el.hide();
        }
        if(this.dossierHeaderRecord){
            this.dossierHeaderRecord.$el.hide();
        }
        this.dossierEntry.hide();
        super.switchToAlbum();
        var displayMode = DeviceUtils.isFullScreenPageNav() ? 'tablet' : 'desktop';
        this.setTaskLayout(displayMode);
    }

    onDOMUpdated () {
        super.onDOMUpdated();

        /*var finalNavHeight = $(window).height() - this.navigator.$el.offset().top - this.navigatorTabBarEl.height();
        this.navigator.$el.css({
            height: finalNavHeight,
            'overflow-y': 'scroll'
        });*/
    }

    switchToDossier () {
        //We simulate the view switch by hiding the navigator
        this.navigator.$el.hide();
        if (this.navigatorTabBar) {
            this.navigatorTabBar.$el.hide();
        }
        this.getAlbumElement().hide();
        this.dossierEntry.show();
        this.taskHeader.setupContextAction(this.getRecordContextActions());
    }

    switchToMainNavigator(){
        this.currentRecord = undefined;
        this.recordKey = undefined;
        this.unloadRecordContext();
        //We simulate the view switch by hiding the dossier
        this.taskHeader.setupContextAction([]);
        window.App.taskMenu.requestTaskSpecificIconUpdate(this.taskConfig.getId(), null);
        if(this.recordBanner){
            this.recordBanner.$el.hide();
        }
        if(this.dossierHeaderRecord){
            this.dossierHeaderRecord.$el.hide();
        }
        this.dossierEntry.hide();
        this.getAlbumElement().hide();
        this.navigator.$el.show();
        if (this.navigatorTabBar) {
            this.navigatorTabBar.$el.show();
        }
        var displayMode = DeviceUtils.isFullScreenPageNav() ? 'tablet' : 'desktop';
        this.setTaskLayout(displayMode);
        this.rewriteUrlBack();
    }

    rewriteUrlBack() {
        if(! App.router.isNavigatorBack) {
            let currentFragment = Backbone.history.getFragment();
            App.router.navigate('#'+currentFragment.slice(0,currentFragment.lastIndexOf('/')), {trigger: false, replace: false});
        }
    }

    buildOrSetPageNavigator (record, table) {
        if (!this.pageNavigator) {
            let displayNewPage = this.availableNewPages && this.availableNewPages.pages.length > 0;
            this.pageNavigator = new PageNavigatorView({
                model: record.clone(),
                el: this.pageNavEl,
                taskConfig: this.taskConfig,
                tableConfig: table,
                displayActionsButtons: !DeviceUtils.isFullScreenPageNav(),
                domainContext: this.domainContext,
                displayAddPage: displayNewPage
            });
            this.listenTo(this.pageNavigator, 'pageSelected', this.onPageNavigatorPageSelected);
            this.listenTo(this.pageNavigator, 'addPage', this.onAddPageRequest);
            this.listenTo(this.pageNavigator, 'removePage', this.onRemovePageRequest);
        } else {
            this.pageNavigator.model.set(record.attributes);
        }

        this.pageNavigator.render();

        if (DeviceUtils.isFullScreenPageNav()) {
            if (this.selectedPage || !this.pageNavTabBar) {
                this.pageNavTabBarEl = this.$el.find('.pageNavTabBar');
                var tabs = [
                    {
                        id: 'home',
                        name: 'Home',
                        icon: 'home',
                        enabled: true
                    }, {
                        id: 'back',
                        name: 'Retour',
                        icon: 'arrow-left',
                        enabled: true
                    }
                ];
                tabs.push({
                    id: 'add',
                    name: 'Page',
                    icon: 'plus',
                    enabled: true
                });

                let mergePopupMenu = this.tablePopup.appendOverride(this.taskPopup);
                if(mergePopupMenu.hasActions([PopupMenu.SCOPE_ALWAYS, PopupMenu.SCOPE_SINGLE])) {
                    tabs.push({
                        id : 'popupmenu',
                        name : 'actions',
                        icon : 'cogs',
                        enabled : true,
                        popupmenu : mergePopupMenu
                    })
                }

                this.pageNavTabBar = new TabBarView({
                    el: this.pageNavTabBarEl,
                    prefix: 'pageNav',
                    tabs: tabs,
                    dockLocation: 'bottom',
                    domainContext: this.domainContext
                });
                this.pageNavTabBar.render();
                this.listenTo(this.pageNavTabBar, 'tabPressed', this.onPageNavTabPressed.bind(this));
            }
            this.pageNavTabBar.$el.show();
        } else {
            if(!this.availableNewPages || this.availableNewPages.pages.length === 0){
                this.pageNavigator.$el.find('.pageNavAdd').hide();
            }

            if(this.dossierTaskActionMenu === undefined) {
                this.buildRightMenu(this.dossierOperations);
                
                let mergePopupMenu = this.tablePopup.appendOverride(this.taskPopup);
                let popupActions = mergePopupMenu.getVisibleActions([PopupMenu.SCOPE_ALWAYS, PopupMenu.SCOPE_SINGLE]);
                for(let i in popupActions) {
                    let item = popupActions[i];
                    if(!this.menuContainsAction(item.getActionId())) {
                        let popUpIconPath = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/'+item.getIcon());
                        let popupMenuActionItem = new MenuItemView({
                            label: item.getLabel(),
                            iconPath: popUpIconPath,
                            actionType: 'popupmenu',
                            actionArguments: item.getNotification(),
                            id : item.getActionId()
                        });
                        this.dossierOperations.push(popupMenuActionItem);
                        this.desktopActionsMenu.addMenuItem(popupMenuActionItem, true);
                    }
                }
            }
        }
        this.pageNavigator.$el.show();

        this.pageNavigator.initialTopOffset = this.pageNavigator.$el.offset().top;
    }

    menuContainsAction(id) {
        for(let i in this.dossierOperations) {
            if(this.dossierOperations[i].id === id) {
                return true;
            }
        }
        return false;
    }

    getDefaultNavigatorPresentation(){
        return 'header';
    }

    getTplsOfType (type) {
        var lists = this.taskModel.get('lists');
        if (lists && lists.templates) {
            return _.filter(this.taskModel.get('lists').templates, function (tpl) {
                return tpl.scope === type;
            });
        } else {
            return [];
        }
    }

    onPageNavTabPressed (tabId) {
        if (tabId === 'home') {
            window.App.backToDesktop();
        } else if (tabId === 'add') {
            this.onAddPageRequest();
        } else if (tabId === 'back') {
            this.switchToMainNavigator();
            this.pageNavTabBar.$el.hide();
        } else if (tabId === 'popupmenu') {
            this.onPopupMenuClick([PopupMenu.SCOPE_ALWAYS, PopupMenu.SCOPE_SINGLE]);
        }
    }

    getTabById(tabId) {
        let navigator = this.selectedPage ? this.pageTabBar : this.navigatorTabBar;
        for (var tab in navigator.tabs) {
            if (navigator.tabs[tab].id === tabId) {
                return navigator.tabs[tab];
            }
        }
        return undefined;
    }

    onPageTabPressed (tabId) {
        if (tabId === 'home') {
            this.pageTabBar.setTabUnmodified();
            window.App.backToDesktop();
        } else if (tabId === 'back') {
            this.backToDossierList();
        } else if (tabId === 'save') {
            this.onValidationClick();
        } else if (tabId === 'delete') {
            this.onRemovePageRequest();
        } else if (tabId === 'popupmenu') {
            this.onPopupMenuClick([PopupMenu.SCOPE_ALWAYS, PopupMenu.SCOPE_SINGLE]);
        }
    }

    backToDossierList() {
        this.rewriteUrlBack();
        if(this.pageTabBar !== undefined) {
            this.pageTabBar.setTabUnmodified();
            if (DeviceUtils.isMobile()) {
                this.preventResize = false;
            }
            var out = $(window).width();
            this.pageView.$el.animate({
                left: out
            }, {
                duration: 250,
                queue: false
            });
            this.pageNavTabBar.$el.show();
            var me = this;
            this.pageTabBar.$el.animate({
                left: out
            }, {
                duration: 250,
                queue: false,
                complete: function () {
                    me.pageTabBar.$el.hide();
                }
            });
        }
    }

    buildOrSetPageView (selectedPage) {
        if (!this.pageView) {
            this.pageView = new PageView({
                model: undefined,
                taskConfig: this.taskConfig,
                tableConfig: selectedPage.getDataPage().getTable(),
                el: this.recordEl,
                page: selectedPage,
                domainContext: this.domainContext
            });
            this.listenTo(this.pageView, 'optionsClicked', this.onOptionsClick.bind(this));
            this.listenTo(this.pageView, 'validationClicked', this.onValidationClick.bind(this));
        } else {
            this.pageView.setPage(selectedPage);
            this.pageView.deleteRecordViewContent();
        }
        this.pageView.setMode(this.mode);
        this.pageView.render();
        this.selectedPage = selectedPage;

        //Moving the page outside of the view so that we can make it
        //appear with animation when it's loaded
        if (DeviceUtils.isFullScreenPageNav()) {
            this.pageView.$el.css('left', $(window).width() + 'px');
            var baseHeight = this.pageNavigator.$el.height() + 10;
            this.pageView.$el.height(baseHeight);
        }

        this.pageView.$el.show();

    }

    onNavigatorNewEntry () {
        this.mode = 'newEntry';
        var newGroups = this.model.get('newModel').groups;
        var emptyModel = new RecordModel({
            icon: CClientConfiguration.getGlobalImage(CClientConfiguration.APP_LOGO_BLACK),
            backgroundColor: '#1e8f00',
            newText: 'Nouveau dossier'
        });
        this.switchToDossier();
        this.buildOrSetRecordBanner(emptyModel);
        var displayMode = DeviceUtils.isFullScreenPageNav() ? 'tablet' : 'desktop';
        this.setTaskLayout(displayMode);
        this.buildOrSetPageNavigator(emptyModel);
        this.buildOrSetPageView({
            icon: 'images/newpage.png',
            dominantColor: '#1e8f00',
            headerTextColor: 'white'
        });
        this.pageView.setRecordView(newGroups);
    }

    getMainRecordLoadingText(){
        return 'Chargement du dossier';
    }

    loadRecord () {
        var displayMode = DeviceUtils.isFullScreenPageNav() ? 'tablet' : 'desktop';
        this.setTaskLayout(displayMode);
        super.loadRecord();
    }

    makeMainRecordRetreivalCall(){
        return new Promise((accept, reject) => {
            var dossierContext = this.contexts.getOrCreate('key', true);
            var keys = this.currentRecord.outputKeys();
            this.recordKey = keys;
            dossierContext.addFields(keys);

            let dossierRequest = new GetDossierRequest(this.domainContext, this.taskConfig.getId());
            dossierRequest.setKeyContext(dossierContext);
            let tableId = this.taskConfig.getTableID();
            let loadingReqId;
            LoadingMask.requestLoading('Chargement du dossier')
                .then((reqId) => {
                    loadingReqId = reqId;
                    return Promise.all([
                        Server.performRequest(dossierRequest),
                        CClientConfiguration.getTable(tableId,this.taskConfig.getContext())
                    ]);
                })
                .then(([result, table]) => {
                    this.onDossierReceived(keys, result, table);
                })
                .catch(App.displayErrorMessage)
                .then(function() {
                    LoadingMask.hide(loadingReqId);
                    accept();
                });
        });
    }

    closeRecord (evt) {
        this.pageNavigator.$el.hide();
        this.pageNavigator.reset();
        this.recordBanner.$el.hide();
        this.pageView.$el.hide();
        this.dossierEntry.hide();

        this.navigator.$el.show();
    }

    onPageNavigatorPageSelected (selectedPage) {
        this.loadPage(selectedPage, 'modify');
    }

    onOptionsClick (evt) {

    }

    onValidationClick (evt) {
        var recordView = this.pageView.recordView;
        var values = recordView.getFieldsValues();
        var submitMode = this.mode === 'modify' ? 'modify' : 'new';

        var validationContext = new Context({contextId : 'page'});
        let keys = this.pageView.getKeys();
        validationContext.addParameter('pageId', keys.pageId);
        validationContext.addParameter('pageOccurrence', keys.pageOccurrence);
        validationContext.addParameter('dossierId', keys.dossierId);

        validationContext.addFields(values);

        let submitPageRequest = new SubmitPageRequest(this.domainContext, this.getConfig().getId());
        submitPageRequest.setOperation(submitMode);
        submitPageRequest.setPageContext(validationContext);

        let loadingReqId;
        LoadingMask.requestLoading(this.mode === 'modify' ? 'Modification' : 'Création' + ' de la page')
            .then(function(reqId) {
                loadingReqId = reqId;
                return Server.performRequest(submitPageRequest);
            })
            .then(this.onValidationSuccess.bind(this))
            .catch(App.displayErrorMessage)
            .then(function() { LoadingMask.hide(loadingReqId);});
    }

    onValidationSuccess (response) {
        ToastFactory.displayText('Sauvegardé !', 2000);
        if(this.pageTabBar !== undefined) {
            this.pageTabBar.setTabUnmodified();
        }
        this.currentPageData = this.pageView.getKeys();
        this.loadRecord();
    }

    loadPage ({page, theme}, newMode) {
        this.mode = newMode;
        this.buildOrSetPageView(page, theme);

        let keys = {
            dossierId: this.recordKey[0].value,
            pageOccurrence: page.getOccurrence(),
            pageId: page.getId()
        };
        this.pageView.setKeys(keys);

        //looking for the maximum width to get full fields range
        var minLeft = Number.MAX_VALUE;
        var maxLeft = 0;
        this.pageView.$el.find('.recordField').each(function () {
            var currentLeft = $(this).offset().left;
            minLeft = Math.min(minLeft, currentLeft);
            var finalLeft = currentLeft + $(this).width();
            maxLeft = Math.max(maxLeft, finalLeft);
        });

        this.getPageActions()
            .then(() => {
                if (DeviceUtils.isFullScreenPageNav()) {
                    let pagesRights = this.taskConfig.getPagesAccess();
                    var recordViewHeight = this.pageView.$el.height() - this.pageView.pageHeader.height();
                    this.pageView.$el.find('.recordContainer').css('height', recordViewHeight);
                    this.pageView.$el.css('width', $(window).width());
                    if(this.pageTabBar) {
                        this.pageTabBar.$el.empty();
                    }
                    let tabs = [
                        {
                            id: 'home',
                            name: 'Home',
                            icon: 'home',
                            enabled: true
                        }, {
                            id: 'back',
                            name: 'Retour',
                            icon: 'arrow-left',
                            enabled: true
                        }, {
                            id: 'save',
                            name: 'Valider',
                            icon: 'floppy-o',
                            enabled: pagesRights[page.getId()].indexOf('M') >= 0
                        }, {
                            id: 'delete',
                            name: 'Effacer',
                            icon: 'trash',
                            enabled: pagesRights[page.getId()].indexOf('D') >= 0
                        }
                    ]
                    let mergePopupMenu = this.tablePopup.appendOverride(this.taskPopup);
                    if(this.pagePopup) {
                        mergePopupMenu = mergePopupMenu.appendOverride(this.pagePopup);
                    }
                    if(mergePopupMenu.hasActions([PopupMenu.SCOPE_ALWAYS, PopupMenu.SCOPE_SINGLE])) {
                        tabs.push({
                            id : 'popupmenu',
                            name : 'actions',
                            icon : 'cogs',
                            enabled : true,
                            popupmenu : mergePopupMenu
                        })
                    }
        
                    this.pageTabBarEl = this.$el.find('.pageTabBar');
                    this.pageTabBar = new TabBarView({
                        el: this.pageTabBarEl,
                        prefix: 'pageNav',
                        tabs: tabs,
                        dockLocation: 'bottom',
                        domainContext: this.domainContext
                    });
                    this.pageTabBar.render();
                    this.listenTo(this.pageTabBar, 'tabPressed', this.onPageTabPressed.bind(this));
                    this.pageTabBar.$el.css('left', $(window).width());
                    this.pageTabBar.$el.show();
        
                    this.pageTabBar.setTabVisibility('delete', this.mode !== 'newPage');
        
                    this.pageView.$el.animate({
                        left: 0
                    }, {
                        duration: 250,
                        queue: false
                    });
        
                    var me = this;
                    this.pageTabBar.$el.animate({
                        left: 0
                    }, {
                        duration: 250,
                        queue: false,
                        complete: function() {
                            me.pageNavTabBar.$el.hide();
                        }
                    });
                    this.pageView.$el.on('SAIFieldUpdate',this.onFieldChange.bind(this));
                } else {
                    this.buildOrSetPageNavigator(this.currentRecord, this.taskConfig.getTableID());
                }
                var displayMode = DeviceUtils.isFullScreenPageNav() ? 'tablet' : 'desktop';
                this.setTaskLayout(displayMode);
                if(DeviceUtils.isMobile() && displayMode !== 'desktop'){
                    this.preventResize = true;
                }
            });
        App.router.navigate('#'+Backbone.history.getFragment()+'/'+keys.pageId + '§' + keys.pageOccurrence,{trigger: false, replace: false});
    }

    onFieldChange() {
        this.pageTabBar.setTabModified();
    }

    isAllowedToBrowseEntity(){
        return this.canRead && this.canReadPages;
    }

    onDossierReceived (keys, response, table) {
        this.cancelFirstPageSelection = false;
        AbstractTask.setReadOnly(response.task, this.getConfig().getPagesAccess());

        //Hiding navigator to display the dossier in full screen
        this.switchToDossier();

        var recordContext = this.contexts.getOrCreate('record', true);
        recordContext.addFields(this.currentRecord.outputAllValues());

        //Building the page navigator if not done already
        this.buildOrSetPageNavigator(this.currentRecord, table);
        if (this.pageNavigator.onDOMUpdated) {
            this.pageNavigator.onDOMUpdated();
        }

        //Preparing the holder for the dossier header
        this.buildOrSetRecordBanner(this.currentRecord);

        let headerPage = this.taskConfig.getHeaderPage();
        if(headerPage !== undefined) {
            //We only build the dossier header if the configuration doesn't specify
            //a page as the dossier header or if the user doesn't have access to it
            let pagesAccess = this.taskConfig.getPagesAccess();
            if(pagesAccess[headerPage] === undefined || pagesAccess[headerPage].indexOf('R') < 0) {
                //No access to page
                this.buildDossierHeader();
            }
        }

        //Setting up the tree of pages
        let builtPages = this.pageNavigator.setPagesCollection(response.task.pages);
        this.pageNavigator.render();
        if(this.pageView) {
            this.pageView.setRecordView([]);
        }

        if (!DeviceUtils.isFullScreenPageNav()) {
            var desktopFinalPageNavHeight = $(window).height() - this.pageNavEl.offset().top - 20;
            this.pageNavigator.$el.css('height', desktopFinalPageNavHeight);
            //this.recordEl.$el.css('height', desktopFinalPageNavHeight);
            //this.fixNavigatorHeightAndPosition();
            //$(window).scroll(this.fixNavigatorHeightAndPosition.bind(this));
        } else {
            //The device is small enough, the navigator must take all the available height
            var finalPageNavHeight = this.pageNavTabBarEl.offset().top - this.pageNavEl.offset().top;
            this.pageNavigator.$el.css('height', finalPageNavHeight);
        }
        
        var displayMode = DeviceUtils.isFullScreenPageNav() ? 'tablet' : 'desktop';
        this.setTaskLayout(displayMode);

        let dossierFragment = keys.reduce(this.keyReduce,'');
        App.router.navigate('#'+Backbone.history.getFragment()+'/'+dossierFragment,{trigger: false, replace: false});
        if(this.currentPageData !== undefined) {
            if (!DeviceUtils.isFullScreenPageNav()) {
                //If the last operation was a modify/delete/new we reloaded the dossier and we've to select the last selected page
                let latestPage = this.pageView.getPage();
                let pageToSelect = {
                    theme: latestPage.getDataPage().getThemeID(),
                    page: this.pageNavigator.getPagesCollection()[latestPage.getDataPage().getThemeID()].pagesMap[latestPage.getId() + '/' + latestPage.getOccurrence()]
                };
                this.loadPage(pageToSelect, 'modify');
            } else {
                this.backToDossierList();
            }
            this.currentPageData = undefined;
        } else if(builtPages.length > 0 && !DeviceUtils.isFullScreenPageNav()) {
            let me = this;
            setTimeout(function() {
                if(!me.cancelFirstPageSelection) {
                    me.pageNavigator.selectFirstPage();
                }
            }, 5);
        }
    }

    keyReduce(accumulator, item) {
        if(accumulator !== '') {
            accumulator += '$';
        }
        accumulator += item.key + '§' + item.value;
        return accumulator;
    }

    buildDossierHeader(groups){
        this.dossierHeaderEl.empty();
        if(groups === undefined || groups.length === 0){
            return;
        }

        if(_.filter(groups, function(group){ return group.column > 0;})){
            if(!this.dossierHeaderRecord){
                var displayMode = DeviceUtils.isFullScreenPageNav() ? 'tablet' : 'desktop';
                this.dossierHeaderRecord = new RecordView({
                    dominantColor: '#333',
                    displayMode: displayMode,
                    el: this.dossierHeaderEl,
                    groups: [],
                    taskId: this.taskModel.get('taskId'),
                    domainContext: this.domainContext
                });
            }
            this.dossierHeaderRecord.setGroups(groups);
            this.dossierHeaderRecord.render();

            var minTop = Number.MAX_VALUE;
            var maxTop = 0;
            this.dossierHeaderRecord.$el.find('.group').each(function (index) {
                var grpEl = $(this);
                var grpTop = $(this).offset().top;
                minTop = Math.min(minTop, grpTop);
                grpEl.find('.recordField').each(function (chldIndex) {
                    var fldTop = $(this).offset().top;
                    var fldBot = fldTop + $(this).height();
                    if (fldTop > 0 && fldBot > 0) {
                        maxTop = Math.max(maxTop, fldBot);
                    }
                });
            });
            if(minTop < 50){
                minTop = 0;
            }
            var finalHeight = maxTop - minTop;
            this.dossierHeaderRecord.$el.css({
                height: finalHeight
            });
        }
    }

    onBackToNav (ev) {
        //Back to navigator from dossier, we've to hide everything that is not relevant
        this.switchToMainNavigator();
    }

    onAddPageRequest() {
        //Building an array of all pages that can be created
        let acls = this.taskConfig.getPagesAccess();
        let allowed = [];
        for (let pageId in acls) {
            let pageAcls = acls[pageId];
            if (pageAcls !== undefined && pageAcls.indexOf('N') >= 0) {
                allowed.push(pageId);
            }
        }

        let loadingReqId;
        LoadingMask.requestLoading('Chargement du dossier')
            .then((reqId) => {
                loadingReqId = reqId;
                return CClientConfiguration.getTable(this.taskConfig.getTableID(), this.taskConfig.getContext());
            })
            .then((table) => {
                let themes = table.getThemes();
                let popupMenuConfig = {
                    actions: []
                };
                let dPages = table.getDataPages();
                for(let i in themes) {
                    let theme = themes[i];
                    let themeConfig = {
                        subItems: [],
                        label: theme.getLabel(),
                        icon: theme.getIcon()
                    };
                    let themeIsUsed = false;
                    for(let j in dPages) {
                        let dPage = dPages[j];
                        if (dPage.getThemeID() !== theme.getId() || !allowed.includes(dPage.getId())) {
                            continue;
                        }
                        //This is a page that can be created and is available in the current theme
                        themeConfig.subItems.push({
                            label: dPage.getLabel(),
                            icon: dPage.getIcon(),
                            actionId: dPage.getId()
                        });
                        themeIsUsed = true;
                    }
                    if(themeIsUsed) { popupMenuConfig.actions.push(themeConfig); }
                }
                if(popupMenuConfig.actions.length === 0) {
                    throw new Error('Aucune page n\'est disponible à la création');
                }
                this.newPagePopup = new PopupMenuNavigator({
                    popupmenu: new PopupMenu(popupMenuConfig),
                    domainContext: this.domainContext,
                    scope: ['AllVisibleRecords']
                });
                this.newPagePopup.display();
                this.newPagePopup.on('actionSelected', this.onNewPageSelected.bind(this, table))
            })
            .catch(App.displayErrorMessage)
            .then(function () { LoadingMask.hide(loadingReqId); });
    }

    onRemovePageRequest (pageMeta) {
        DialogUtils.displayDialog({
            title:'',
            message:'Voulez-vous vraiment supprimer cette page ?'
        },this.onDeleteConfirm.bind(this));
    }

    onDeleteConfirm(action) {
        if(action === 'cancel') { return; }

        var submitMode = 'delete';
        var validationContext = new Context({contextId : 'page'});
        let keys = this.pageView.getKeys();
        validationContext.addParameter('pageId', keys.pageId);
        validationContext.addParameter('dossierId', keys.dossierId);
        validationContext.addParameter('pageOccurrence', keys.pageOccurrence);
        var recordView = this.pageView.recordView;
        var values = recordView.getFieldsValues();
        validationContext.addFields(values);
        let submitPageRequest = new SubmitPageRequest(this.domainContext, this.taskConfig.getId());
        submitPageRequest.setOperation(submitMode);
        submitPageRequest.setPageContext(validationContext);

        let loadingReqId;
        LoadingMask.requestLoading('Suppression de la page')
            .then(function(reqId) {
                loadingReqId = reqId;
                return Server.performRequest(submitPageRequest);
            })
            .then(this.onRemoveSuccess.bind(this))
            .catch(App.displayErrorMessage)
            .then(function() {
                LoadingMask.hide(loadingReqId);
            });
    }

    onRemoveSuccess(response) {
        ToastFactory.displayText('Supprimé !', 2000);
        if(this.pageTabBar !== undefined) {
            this.pageTabBar.setTabUnmodified();
        }
        this.loadRecord();
    }

    onNewPageSelected(table, item) {
        let datapageId = item.element.actionId;
        let currentPage = new PageInstance(this.taskConfig.getPage(datapageId).config, this.taskConfig, table, this.domainContext);
        this.newPagePopup.hide();
        this.buildOrSetPageView(currentPage);
        this.mode = 'newPage';

        let prepPageRequest = new PrepareNewPageRequest(this.domainContext, this.taskConfig.getId());
        //Creating or modifying the page context to match the page creation context
        var pageContext = new Context({contextId : 'page'});
        pageContext.addParameter('pageId', currentPage.getId());
        pageContext.addParameter('dossierId', this.recordKey[0].value);
        //Retrieving the max occurrence of the current page in the dossier in order to set it
        let curPages = this.pageNavigator.getPagesCollection();
        let themeId = currentPage.getDataPage().getThemeID();
        let themeEntries = curPages[themeId];
        let occurrence = 1;
        if(themeEntries !== undefined) {
            let max = 0;
            for(let i in themeEntries.pages) {
                let itP = themeEntries.pages[i];
                if(itP.getId() === currentPage.getId()) {
                    max = Math.max(max, itP.getOccurrence());
                }
            }
            occurrence = max + 1;
        }
        pageContext.addParameter('pageOccurrence', occurrence);
        prepPageRequest.setPageContext(pageContext);

        prepPageRequest.setRecordContext(this.contexts.getOrCreate('record', false));

        let me = this;
        LoadingMask.requestAsyncOperation('Préparation de la nouvelle page', Server.performRequest(prepPageRequest), (response) => {
            me.mode = 'newPage';
            let prepPage = new PageInstance(response.screen, me.taskConfig, table, me.domainContext);
            prepPage.setTitle('Nouveau : ' + prepPage.getDataPage().getLabel());
            me.loadPage({ page: prepPage, theme: themeId }, 'new');
            me.switchToDossier();
        }, App.displayErrorMessage);
    }

    resizeTask (mode) {
        this.setTaskLayout(mode);
    }

    setTaskLayout (mode){
        if(this.preventResize){
            return;
        }

        if(this.dossierEntry.is(':visible')){
            if(mode === 'tablet'){
                this.dossierEntry.removeClass('row');
                this.pageNavEl.removeClass('col-md-3');
                this.recordEl.removeClass('col-md-9');
                this.recordEl.css({
                    width: $(window).width()
                });
                if (this.pageNavTabBarEl.is(':visible')) {
                    this.pageNavigator.$el.css({
                        height: this.pageNavTabBarEl.offset().top - this.pageNavigator.$el.offset().top
                    });
                } else {
                    this.pageNavigator.$el.css({
                        height: this.pageTabBar.$el.offset().top - this.pageNavigator.$el.offset().top
                    });
                }
            }else if(mode === 'desktop'){
                this.dossierEntry.addClass('row');
                this.pageNavEl.addClass('col-md-3');
                this.recordEl.addClass('col-md-9');
                this.recordEl.css({
                    width: ''
                });
                this.recordEl.show();
                if(this.pageNavTabBar){
                    this.pageNavTabBarEl.hide();
                }
                if(this.pageTabBar){
                    this.pageTabBarEl.hide();
                }
                this.recordEl.children('.innerPage').height(this.pageNavigator.$el.height());
            }
            this.pageNavigator.resize(mode);
            if(this.pageView){
                this.pageView.resize(mode, this.pageNavigator.$el.height() - this.pageView.pageHeader.height());
            }

        }else{
            this.resizeMainNav(mode);
        }
    }

    handleTaskHeaderAction (action, actionArguments){
        if(action === 'close'){
            if(this.dossierEntry.is(':visible') || this.getAlbumElement().is(':visible')){
                //We're now in the navigator. If we can't create and there is no filter
                //and there is only 1 element, then if we are quitting it means that
                //we don't want to create another entry thus we can pop the task immediately
                if(!this.navigator.getCurrentRecords().length === 1 && !this.canCreate){
                    window.App.popTask();
                }else{
                    this.switchToMainNavigator();
                    this.buildRightMenu(this.taskOperationMenu);
                }
            }else{
                window.App.popTask();
            }
        } else if(action === 'refresh') {
            this.navigator.refreshData();
        } else if(action === 'newpage') {
            this.onAddPageRequest();
        } else if (action === 'deletepage'){
            this.onRemovePageRequest();
        } else if(action === 'savepage') {
            this.onValidationClick();
        } else {
            super.handleTaskHeaderAction(action, actionArguments);
        }
    }

    unloadRecordContext(){
        this.contexts.removeContext('page');
        this.contexts.removeContext('record');
        this.contexts.removeContext('key');
    }

    getNavigatorTabBarTabs() {
        let superPromise = super.getNavigatorTabBarTabs();
        return new Promise((accept, reject) => {
            superPromise.then((tabs) => {
                let index = -1;
                for(let i = 0; i < tabs.length; i++) {
                    if(tabs[i].id === 'new') {
                        index = i;
                        break;
                    }
                }

                if(index > -1) {
                    tabs.splice(index, 1);
                }
                accept(tabs);
            });
        });
    }

    getPageActions() {
        let me = this;
        return new Promise((accept, reject) => {
            let pageId = this.pageView.getPage().getId();
            if(this.pageTaskActionMenu === undefined || this.pageTaskActionMenu[pageId] === undefined) {
                this.buildRightMenu(this.dossierOperations);
                if(this.tablePopup) {
                    let popupActions = this.tablePopup.getVisibleActions([PopupMenu.SCOPE_ALWAYS, PopupMenu.SCOPE_SINGLE]);
                    for(let i in popupActions) {
                        let item = popupActions[i];
                        if(!this.menuContainsAction(item.getActionId())) {
                            let popUpIconPath = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/'+item.getIcon());
                            let popupMenuActionItem = new MenuItemView({
                                label: item.getLabel(),
                                iconPath: popUpIconPath,
                                actionType: 'popupmenu',
                                actionArguments: item.getNotification(),
                                id : item.getActionId()
                            });
                            this.dossierOperations.push(popupMenuActionItem);
                            this.desktopActionsMenu.addMenuItem(popupMenuActionItem, true);
                        }
                    }
                }
                if(this.taskPopup) {
                    let popupActions = this.taskPopup.getVisibleActions([PopupMenu.SCOPE_ALWAYS, PopupMenu.SCOPE_SINGLE]);
                    for(let i in popupActions) {
                        let item = popupActions[i];
                        if(!this.menuContainsAction(item.getActionId())) {
                            let popUpIconPath = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/'+item.getIcon());
                            let popupMenuActionItem = new MenuItemView({
                                label: item.getLabel(),
                                iconPath: popUpIconPath,
                                actionType: 'popupmenu',
                                actionArguments: item.getNotification(),
                                id : item.getActionId()
                            });
                            this.dossierOperations.push(popupMenuActionItem);
                            this.desktopActionsMenu.addMenuItem(popupMenuActionItem, true);
                        }
                    }
                }
            }
            this.desktopActionsMenu.removeActionsWithScope('page');
            CClientConfiguration.getPopupMenu('datapage', this.getConfig().getTableID() + '.' + this.pageView.getPage().getId(), this.domainContext)
                .then((pagePopup) => {
                    me.pagePopup = pagePopup;
                    if(me.pageTaskActionMenu === undefined) {
                        me.pageTaskActionMenu = {};
                    }
                    me.pageTaskActionMenu[pageId] = pagePopup;
                    let popupActions = pagePopup.getVisibleActions([PopupMenu.SCOPE_ALWAYS, PopupMenu.SCOPE_SINGLE]);
                    for(let i in popupActions) {
                        let item = popupActions[i];
                        if(item.hasSubItems()) {
                            let popUpIconPath = Server.getTokenedUrl('configuration/' + me.domainContext.getId() + '/image/highres,big/128,100,64,36/'+item.getIcon());
                            let popupMenuActionItem = new MenuItemView({
                                label: item.getLabel(),
                                iconPath: popUpIconPath,
                                actionType: 'popupmenu',
                                actionArguments: item.getNotification(),
                                id : item.getActionId(),
                                item: item,
                                actionScope: 'page'
                            });
                            me.dossierOperations.push(popupMenuActionItem);
                            me.desktopActionsMenu.addMenuItem(popupMenuActionItem, true);
                        } else if(!this.menuContainsAction(item.getActionId())) {
                            let popUpIconPath = Server.getTokenedUrl('configuration/' + me.domainContext.getId() + '/image/highres,big/128,100,64,36/'+item.getIcon());
                            let popupMenuActionItem = new MenuItemView({
                                label: item.getLabel(),
                                iconPath: popUpIconPath,
                                actionType: 'popupmenu',
                                actionArguments: item.getNotification(),
                                id : item.getActionId(),
                                actionScope: 'page'
                            });
                            me.dossierOperations.push(popupMenuActionItem);
                            me.desktopActionsMenu.addMenuItem(popupMenuActionItem, true);
                        }
                    }
                    accept();
                })
                .catch((e) => {
                    App.displayErrorMessage(e);
                    reject(e);
                });
        });
    }

    displayPage(datapageId, pageOccurrence) {
        this.cancelFirstPageSelection = true;
        let pageInstance = this.pageNavigator.getPageInstance(datapageId, pageOccurrence);
        this.loadPage(pageInstance, 'modify');
    }
}

export { DossierTask };
