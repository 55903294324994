'use strict';

import Backbone from 'backbone';

import App from 'App';
import Server from 'server/Server';
import { SAIView } from '../../../../Additions';
import CClientConfiguration from 'parametrage/CClientConfiguration';
import ColorUtils from 'utils/ColorUtils';

class PopupMenuNavigator extends SAIView {
    constructor(options){
        super(options);

        this.template = require('ejs-loader!templates/modalPopup/ModalPopup.ejs');
        this.btnTemplate = require('ejs-loader!templates/modalPopup/ModalPopupButton.ejs');
        this.tagName = 'div';
        this.id = '';
        this.className = '';
        this.events = {};

        this.popupmenu = options.popupmenu || [];
        this.scope = options.scope;

        Backbone.View.apply(this, arguments);
    }

    initialize (options) {
        this.domainContext = options.domainContext;
        this.uniqId = parseInt(Math.random()*10000);
    }

    render () {
        this.checkInitialized();
        let me = this;

        this.$el = $(this.template({
            operationTitle: 'Actions',
            logo: CClientConfiguration.getGlobalImage(CClientConfiguration.APP_LOGO_BLACK),
            operationColor: App.getConfig().appTheme,
            uniqId: this.uniqId,
            allowDocs: false,
            hideSpinner: true,
            hideCloseButton: false,
            hideTitle: false,
            extraClass: '',
            allowDismiss: true,
            domainContext: this.domainContext,
            colorUtils: ColorUtils
        }));

        var closeBtn = $(this.btnTemplate({
            bootstrapClass: 'btn-success',
            btnClass: 'recordFormAcceptBtn',
            enableOnLoad: 'enableOnLoad',
            disabled: '',
            btnText: 'Annuler',
            role: 'cancel',
            dismiss: 'data-dismiss="modal"'
        }));

        closeBtn.on('click', this.hide.bind(this));

        closeBtn.appendTo(this.$el.find('.modal-footer'));

        this.pageNavigatorEl = this.$el.find('.innerView');
        this.loadingSpin = this.$el.find('.loadingSpinner');

        let allActions = this.popupmenu.getVisibleActions(this.scope, undefined, undefined);
        let actionCounter = 0;
        for (var actionIndex in allActions) {
            var popupItem = allActions[actionIndex];
            var popupItemEl = $('<div class="popup-element"></div>');
            var popupItemElement = popupItem.element;
            var imgSrc = Server.getTokenedUrl('configuration/' + this.domainContext.getId() + '/image/highres,big/128,100,64,36/' + popupItemElement.icon);
            var popupItemIconEl = $('<div class="popup-element-icon"><span class="helper"></span><img src="'+imgSrc+'"></div>');
            popupItemEl.append(popupItemIconEl);
            var popupItemLabelEl = $('<div class="popup-element-label">'+popupItemElement.label+'</div>');
            popupItemEl.append(popupItemLabelEl);
            popupItemEl.attr('data-popup-id', actionCounter);

            this.pageNavigatorEl.append(popupItemEl);

            if(popupItem.hasSubItems()) {
                popupItemEl.addClass('closed');
                let hasVisibleSubItems = false;
                for (var subItemIndex in popupItem.subItems) {
                    var subItem = popupItem.subItems[subItemIndex];
                    if (this.popupmenu.isVisible(subItem, undefined, undefined)) {
                        hasVisibleSubItems = true;
                        var subItemEl = $('<div class="popup-element popup-sub-element"></div>');
                        var subElementImgSrc = '/images/subelement.png';
                        var subItemHeaderEl = $('<div class="popup-element-icon"><img src="'+subElementImgSrc+'"></div><div class="popup-element-label">'+subItem.element.label+'</div>');
                        subItemEl.append(subItemHeaderEl);
                        subItemEl.attr('data-popup-id', actionCounter);
                        subItemEl.hide();
                        subItemEl.on('click', this.onItemClicked.bind(this, subItem.element));
                        this.pageNavigatorEl.append(subItemEl);
                    }
                }
                if(hasVisibleSubItems) {
                    this.pageNavigatorEl.find('div:last-child div img').attr('src','/images/subelement_last.png');
                    popupItemEl.on('click', function() {
                        $(this).toggleClass('closed');
                        let subentries = me.pageNavigatorEl.find('.popup-sub-element[data-popup-id='+ $(this).data('popup-id')+']');
                        if($(this).hasClass('closed')) {
                            subentries.hide();
                        } else {
                            subentries.show();
                        }
                    })
                } else {
                    popupItemEl.remove();
                }
            } else {
                popupItemEl.on('click', this.onItemClicked.bind(this, popupItemElement));
            }
            actionCounter++;
        }
    }

    onItemClicked(item) {
        this.trigger('actionSelected', item, this.$el);
    }

    display () {
        var me = this;
        //rendering popup
        this.render();


        //displaying as modal
        this.$el.on('shown.bs.modal', function () {
            me.modalBody = me.$el.find('.modal-body');
            me.displayPopupMenuItems();
        });

        this.$el.on('hidden.bs.modal', function () {
            $('#'+me.uniqId).remove();
        });

        this.$el.modal('show');
    }

    hide(){
        this.$el.modal('hide');
    }

    displayPopupMenuItems(){
        /*var themeMap = this.pageNavigator.buildThemeMap(this.pages);
        //In case of page model list, we won't get the groups as there will always be only one
        //version of each available page. Thus we've to slightly modify the data so that it matches
        //a standard getDossier call
        for(var key in this.pages.pages){
            this.pages.pages[key].id = this.pages.pages[key].pageId;
            this.pages.pages[key].name = this.pages.pages[key].title;
        }
        var pageGroups = this.pageNavigator.buildPageGroupsMap(this.pages.pages);
        var newModels = this.pageNavigator.buildNewModels(this.pages, themeMap);
        this.pageNavigator.setPagesCollection(newModels, pageGroups);*/
    }
}

export default PopupMenuNavigator;
